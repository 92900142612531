<template>
  <Layout />
</template>

<script>
export default {
  components: {
    Layout: () => import("@/layout/Layout")
  }
}
</script>
