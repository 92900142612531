import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  apiKey: "AIzaSyBaJvMH8cvA2HRodOd2UqrOxacFr1rCAQA",
  authDomain: "ordination-monk2500.firebaseapp.com",
  databaseURL: "https://ordination-monk2500.firebaseio.com",
  projectId: "ordination-monk2500",
  storageBucket: "ordination-monk2500.appspot.com",
  messagingSenderId: "427874628748",
  appId: "1:427874628748:web:1b6ea6e211f695593db7a9",
  measurementId: "G-J0PDZKE4KQ"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);