import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from 'vuex-persistedstate'
// import * as Cookies from 'js-cookie';
import axios from '@/plugins/axios'
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "@/plugins/firebase.init"
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });
import router from '../router'

Vue.use(Vuex);


export default new Vuex.Store({
  key: 'vuex-store',
  plugins: [
    createPersistedState({ storage: {
      getItem: (key) => ls.get(key),
      setItem: (key, value) => ls.set(key, value),
      removeItem: (key) => ls.remove(key),
    }})],
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout
    participantList: null,
    participantFetchTime: null,
    user: null,
    expiryDate: new Date(),
    loading: false,
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_PARTICIPANT_LIST(state, payload) {
      state.participantList = payload;
    },
    SET_PARTICIPANT_FETCH_TIME(state, payload) {
      state.participantFetchTime = payload;
    },
    SET_USER(state, payload) {
      state.user = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },
    SET_EXPIRY_DATE: (state) => {
      // Create a date
      const date = new Date();
      // Add your delta for expiry. I am expiring in one day.
      date.setDate(date.getDate() + 1);
      // Set the state
      state.expiryDate = date;
    },
  },
  actions: {
    fetchDataFromApi ({ commit }) {
      commit('SET_LOADING', true)
      return axios.getData('tabs/db')
        .then((res) => {
          const result = res.data.filter(x => x.id !== '')
          const timestamp = Date.now();
          commit('SET_PARTICIPANT_LIST', result)
          commit('SET_PARTICIPANT_FETCH_TIME', timestamp)
          commit('SET_LOADING', false)
        })
        .catch(err => {
          console.log(err)
          commit('SET_LOADING', false)
        })
    },
    signInApp ({ commit }, { password }) {

      const email = `rgtsoft@rgtcenter.com`

      return new Promise ((resolve, reject) => {
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...

          commit('SET_USER', user)
          commit('SET_EXPIRY_DATE')

          return resolve('yes')
        })
        .catch((error) => {
          // const errorCode = error.code;
          const errorMessage = error.message;
          console.error(errorMessage)

          return reject(errorMessage)
        });
      })
    },
    signOutApp ({ commit }) {

      return signOut(auth).then(() => {
        // Sign-out successful.
        commit('SET_USER', null)
        router.push({ path: "/login" });

      }).catch((error) => {
        // An error happened.
        const errorMessage = error.message;
        console.error(errorMessage)
      });

    }
  },
  getters: {
    getParticipantList: (state) => state.participantList,
    // getRegretList: (state) => state.participantList.filter(x => x.regretNumber !== ''),
    getOrdainList: (state) => state.participantList.filter(x => x.wat !== ''),
    getParticipantFetchTime: (state) => state.participantFetchTime,
    getLoadingStatus: (state) => state.loading,
    // getRowParticipantList (state) {
    //   return row => state.participantList.filter(x => x.row === row)
    // },
    getParticipant: (state) => (key) => {
      console.log(key)
      return  state.participantList.filter(x => x["full-name"].includes(key))
    },
    checkParticipantList: (state) => !!state.participantList,
    checkParticipantFetchTime: (state) => !!state.participantFetchTime,
    isAuthenticated: state => !!state.user,
    isExpired: state => (new Date(state.expiryDate) < new Date()),
  },
});
