<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-7">
        <v-toolbar
          flat
        >
          <v-spacer></v-spacer>
          <v-btn
           rounded
           class="orange darken-1 white--text mr-4"
           @click="initialize"
          >
            <v-icon dark>
              mdi-wrench
            </v-icon>
            โหลดข้อมูลใหม่
          </v-btn>
        </v-toolbar>
        <v-toolbar
          flat
          class="mb-8"
        >
          <v-row wrap>
            <v-col cols="10">
              <v-text-field
                v-model="search"
                placeholder="ค้นหารายชื่อ ธทย."
                class="mt-3 mb-0"
                prepend-icon="mdi-magnify"
                clearable
              ></v-text-field>
            </v-col>
            <v-col v-if="isMobile" cols="1">
              <v-btn icon color="primary" class="mt-2" @click="searchbox">
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </v-col>
            <v-col v-else cols="1">
              <v-btn text color="primary" class="mt-3 d-sm-block d-none" @click="searchbox">
                ค้นหาเพิ่มเติม
              </v-btn>
            </v-col>
          </v-row>
          <v-card v-if="showSearch" class="searchinput">
            <v-row>
            <v-col cols="10">
              <v-combobox
                v-model="centerIndexSelected"
                :items="centerIndex"
                filled
                clearable
                multiple
                background-color="transparent"
                label="กรองโครงการอบรม"
              ></v-combobox>
            </v-col>
            <v-col cols="2">
                <v-select
                  v-model="siteIndexSelected"
                  :items="siteIndex"
                  filled
                  clearable
                  background-color="transparent"
                  label="กรองแถว"
                ></v-select>
              </v-col>
            <v-col cols="1">
              <v-btn icon class="mt-2" @click="searchbox">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
            </v-row>
          </v-card>
        </v-toolbar>
        <v-data-table
          :headers="headers"
          :items="itemsFiltered"
          :items-per-page="50"
          multi-sort
          class="contact-listing-app"
          :loading="load"
          loading-text="กำลังโหลด... รอสักครู่"
        >
          <template v-slot:item.full-name="{ value }">
            <div :inner-html.prop="value | highlight(search)" class="tablethai"></div>
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip :class="checkStatus(item.status)" label small>{{ changeLabel(item.status) }}</v-chip>
          </template>
          <template v-slot:item.site="{ item }">
            <span>{{ siteLabel(item.site) }}</span>
          </template>
          <template v-slot:item.ordain-date="{ item }">
            <span>{{ ordainLabel(item["ordain-date"]) }}</span>
          </template>
          <template v-slot:item.wat="{ item }">
            <v-chip v-show="(item.wat.length > 0)" dark color="info">{{ item.wat }}</v-chip><br>
          </template>
          <template v-slot:item.wat-address="{ item }">
            <small>{{ item["wat-address"] }}</small>
          </template>
          <template v-slot:item.picture-url="{ item }">
            <v-avatar
              size="36px"
            >
              <img
                v-if="checkPic(item)"
                alt="Avatar"
                :src="item['picture-url']"
              >
              <v-icon
                v-else
                color="orange darken-2"
              >
               {{ firstLetter(item.name) }}
              </v-icon>
            </v-avatar>
          </template>
          <template v-slot:no-data>
            <v-progress-circular
              indeterminate
              color="amber"
            ></v-progress-circular>
            Please wait...
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>

export default {
  name: "Home",
  filters: {
    highlight(value, query) {
        return value.replace(new RegExp(query, "ig"), '<span class=\'yellow\'>' + query + '</span>')
    },
  },
  data: () => ({
    page: {
      title: "หน้าค้นหารายชื่อธรรมทายาท",
      icon: "mdi-home"
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "หลัก",
        disabled: true
      },
      {
        text: "ค้นหารายชื่อ",
        disabled: true
      }
    ],
    search: '',
    isMobile: false,
    showSearch: false,
    siteIndexSelected: '',
    centerIndexSelected: []
  }),

  computed: {
    headers() {
      return [
      {
        text: "รูป",
        value: "picture-url",
        sortable: false,
      },
      {
        text: "ชื่อ - นามสกุล",
        align: "start",
        value: "full-name",
        filter: this.nameFilter,
      },
      {
        text: "แถวเวียนประทักษิณ",
        value: "site",
        filter: this.siteFilter,
      },
      // {
      //   text: "กลุ่ม",
      //   value: "group",
      //   sortable: false,
      // },
      {
        text: "เลขที่ขอขมา",
        value: "group-number",
        sortable: false,
      },
      {
        text: "โครงการ",
        value: "from-site",
      },
      // {
      //   text: "วัดบวช",
      //   value: "wat",
      // },
      // {
      //   text: "ที่อยู่วัด",
      //   value: "wat-address",
      // },
      // {
      //   text: "วันที่บวช",
      //   value: "ordain-date",
      // },
      // { text: "สถานะ", value: "status", sortable: false, },
    ]},

    checkData () {
      return this.$store.getters.checkParticipantList
    },

    items () {
      if (this.checkData) {
        return this.$store.getters.getParticipantList
      }
      return []
    },

    siteIndex() {
      if(this.items) {
        return [...new Set(this.items.map(x => parseInt(x.site)))].sort((a, b) => a - b)
      }
      return []
    },

    centerIndex () {
      if(this.items) {
        return [...new Set(this.items.map(x => x["from-site"]))].sort((a, b) => a.localeCompare(b, 'th'))
      }
      return []
    },

    itemsFiltered () {
      if (this.centerIndexSelected.length > 0) {
        return this.items
          .filter(y => {
            return this.centerIndexSelected.includes(y["from-site"])
        })
      }
      return this.items
    },

    load () {
      return this.$store.getters.getLoadingStatus
    }
  },

  created() {
    this.initialize();
  },

  mounted () {
    this.onResize()

    window.addEventListener('resize', this.onResize, { passive: true })
  },

  beforeDestroy () {
    if (typeof window === 'undefined') return

    window.removeEventListener('resize', this.onResize, { passive: true })
  },

  methods: {
    initialize () {
      this.$store.dispatch('fetchDataFromApi')
    },
    onResize () {
      this.isMobile = window.innerWidth < 900
    },
    searchbox () {
      this.showSearch = !this.showSearch;
    },
    changeLabel (text) {
      return (text === "มาสมัคร") ? "มารายงานตัว" : text
    },
    checkPic (url) {
      return (url["picture-url"] !== null) ? true : false
    },
    firstLetter (text) {
      return text.charAt(0)
    },
    siteLabel (text) {
      // if (text === "9") {
      //   return "ไม่ผ่านให้ส่งกลับ"
      // }
      // if (text === "10") {
      //   return "ไม่ผ่านไปเขาแก้ว"
      // }
      // if (text === "7") {
      //   return "ไซต์7 กักตัวโควิด"
      // }

      return text
    },
    ordainLabel (text) {
      if (text === "7 Dec") {
        return "7 ธันวาคม"
      }
      if (text === "9 Dec") {
        return "9 ธันวาคม"
      }

      return text
    },
    checkStatus (val) {
      switch(val) {
        case "ยกเลิกการอบรม (ออก)":
          return "red white--text"
        case "หายตัว":
          return "warning"
        case "บวชเป็นพระ/เณร แล้ว":
          return "green white--text"
        default:
          return "light-blue white--text"
      }
    },
    /**
     * Filter for names column.
     * @param value Value to be tested.
     * @returns {boolean}
     */
    nameFilter(value) {
      // If this filter has no value we just skip the entire filter.
      if (!this.search) {
        return true
      }
      // Check if the current loop value (The dessert name)
      // partially contains the searched word.
      return value.includes(this.search)
    },
    siteFilter(value) {
      if (!this.siteIndexSelected) return true

      return parseInt(value) === this.siteIndexSelected
    }
  }
};
</script>

<style lang="scss">
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
}

.tablethai {
  font-family: 'Noto Serif Thai', serif;
}
</style>